<template>
  <div>
    <div class="branch-container parametrizacao">
      <div class="history" v-if="lastLog">
        <el-button round @click="popupHistoricoAlteracoes = true"
          ><i class="el-icon-timer"></i> Histórico de alterações</el-button
        >
      </div>
      <div class="main-card">
        <div class="card-data">
          <br />
          <el-alert
            title="Aviso"
            type="info"
            :closable="false"
            description="It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"
            show-icon
          >
          </el-alert>
          <vs-col class="title" vs-w="12" vs-sm="12">
            <span
              class=" bold"
              style="color: #636363; font-size: 19px;     margin-left: -10px;"
            >
              Perfis de Usuário para Email
            </span>
          </vs-col>
          <el-select
            v-model="dataParams"
            multiple
            placeholder="Selecione os perfis"
          >
            <el-option
              v-for="item in Perfis"
              :key="item.UniqueId"
              :label="item.Name"
              :value="item.UniqueId"
            >
            </el-option>
          </el-select>
          <vs-row>
            <vs-col class="title" vs-w="12" vs-sm="12">
              <span
                class=" bold"
                style="color: #636363; font-size: 19px;     margin-left: -10px;"
              >
                Email de Aviso de Renovação Automática
              </span>
            </vs-col>
            <vs-col vs-w="4" vs-sm="12">
              <p class="label-input">
                Título do email de aviso <b style="color:red">*</b>
              </p>
              <vs-input
                class="w-full"
                v-model="emailTemplate[0].Title"
                @blur="updateTitle(0)"
              />
            </vs-col>

            <vs-col vs-w="12" vs-sm="12">
              <br />
              <p class="label-input">
                Mensagem do email de aviso: <b style="color:red">*</b>
              </p>
              <el-button type="primary" class="addtag" @click="addTagAction(0)">
                + Inserir Tag</el-button
              >
              <jodit-editor
                id="term-quill"
                ref="term-quill"
                v-model="emailTemplate[0].Body"
                :config="joditConfig"
                read-only="true"
                style="height: 500px;"
              />
            </vs-col>
          </vs-row>
        </div>
        <vs-row vs-type="flex" vs-justify="end" class="mt-5">
          <vs-col
            vs-type="flex"
            vs-justify="end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            ><el-button round @click="voltar()" style="margin-right: 10px"
              >Voltar</el-button
            >
            <vs-button class="button-primary text-center" @click="update()">
              Salvar Alterações
            </vs-button>
          </vs-col>
        </vs-row>
      </div>
    </div>
    <!-- Pop up Histórico de alterações -->
    <el-dialog
      :visible.sync="popupHistoricoAlteracoes"
      close-on-click-modal
      modal-append-to-body
      center
      class="history-change"
    >
      <div class="pop-up-content">
        <div class="pop-up-content-header">
          <img
            src="@/assets/images/icons/ccg-icons/history-icon-ccg.svg"
            alt="Ícone de excluir representante"
            width="24"
            height="24"
          />
          <h3>Histórico de alterações</h3>
        </div>

        <div class="pop-up-content-comparation">
          <div class="pop-up-content-comparation-header">
            <div class="pop-up-content-comparation-header-data">
              Dados
            </div>
            <div class="pop-up-content-comparation-header-divider"></div>
            <div class="pop-up-content-comparation-header-before">
              Antes
            </div>
            <div class="pop-up-content-comparation-header-divider"></div>
            <div class="pop-up-content-comparation-header-after">
              Depois
            </div>
          </div>
          <ul class="pop-up-content-comparation-items">
            <li
              class="pop-up-content-comparation-item"
              v-for="(alteracao, index) in logs"
              :key="index"
            >
              <div class="pop-up-content-comparation-item-data">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Data:
                  </p>
                  <p
                    class="pop-up-content-comparation-item-info"
                    v-if="alteracao.Data"
                  >
                    {{ $utils.dateToDDMMYYYY(alteracao.Data) }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Usuário:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.NomeUsuario }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Ação:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.Acao }}
                  </p>
                </div>
              </div>

              <div class="pop-up-content-comparation-item-divider"></div>

              <div class="pop-up-content-comparation-item-before">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-info">
                    <el-button
                      v-if="alteracao.Antes.length > 0"
                      type="primary"
                      icon="el-icon-view"
                      circle
                      @click="showItens(alteracao.Antes)"
                      >Visualizar</el-button
                    >
                  </p>
                </div>
              </div>

              <div class="pop-up-content-comparation-item-divider-arrow">
                <i class="el-icon-right"></i>
              </div>

              <div class="pop-up-content-comparation-item-after">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    <el-button
                      v-if="alteracao.Depois.length > 0"
                      type="primary"
                      icon="el-icon-view"
                      circle
                      @click="showItens(alteracao.Depois)"
                      >Visualizar</el-button
                    >
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div slot="footer" class="dialog-footer pop-up-footer">
        <el-button type="primary" @click="popupHistoricoAlteracoes = false"
          >Fechar</el-button
        >
      </div>
    </el-dialog>
    <vs-popup title="Inserir Tag na mensagem" :active.sync="modalTags">
      <div class="flex flex-col items-center pt-4">
        <el-select
          v-model="tagSelected"
          filterable
          placeholder="Escolha a tag desejada"
        >
          <el-option
            v-for="item in tags"
            :key="item.Name"
            :label="item.Label"
            :value="item.Name"
          >
          </el-option>
        </el-select>

        <div class="vx-row flex justify-center mt-5">
          <div>
            <vs-button
              color="red"
              class="mr-1"
              @click.native="
                modalTags = false;
                tagSelected = '';
              "
            >
              <span class="font-semibold">
                Cancelar
              </span>
            </vs-button>
            <vs-button
              @click.native="addTagObject()"
              :disabled="tagSelected.lenght <= 0"
            >
              <span class="font-semibold">
                Confirmar
              </span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import instance from "@/axios";
export default {
  components: {},
  data() {
    return {
      objetoTemplate: "",
      popupHistoricoAlteracoes: false,
      indexItem: 0,
      tagSelected: "",
      modalTags: false,
      tags: [],
      logs: {},
      lastLog: false,
      dataParams: [],
      emailTemplate: [{ Title: "", Body: "" }],
      joditConfig: { language: "pt_br" },
      teste: false,
      bolean: true,
      ramos: [],
      modalidadeList: [],
      groupList: [],
      modalityGroupList: [],
      Perfis: []
    };
  },
  async mounted() {
    await this.getData();
    await this.ObterLogAuditoria();
    await this.ObterTagsAutomaticas();
    await this.ObterListaPerfis();
  },
  methods: {
    ...mapActions("seguradora-module", [
      "getAllBranch",
      "getModalitiesByBranch"
    ]),
    voltar() {
      this.$router.push({
        name: "param-list-renovacao"
      });
    },
    updateTitle(index) {
      this.$forceUpdate();
    },
    showItens(text) {
      this.$alert(text, " ", {
        confirmButtonText: "OK"
      });
    },
    addTagObject() {
      this.emailTemplate[this.indexItem].Body += `${this.tagSelected}`;
      this.modalTags = false;
      this.tagSelected = "";
    },
    addTagAction(index) {
      this.modalTags = true;
      this.indexItem = index;
    },

    async ObterTagsAutomaticas() {
      return instance({
        method: "get",
        url: "/api/Core/ObterTagsAutomaticas?tipoTag=3"
      }).then(response => {
        this.tags = response.data.Response;
      });
    },
    async ObterListaPerfis() {
      return instance({
        method: "get",
        url: "/api/UserProfile/GetProfileByCompanyId?userInfoTypeId=2"
      }).then(response => {
        this.Perfis = response.data.Response;
      });
    },
    async ObterLogAuditoria() {
      return instance({
        method: "get",
        url: "/api/Parametros/ObterLogAuditoriaEndossoAniversario"
      }).then(response => {
        this.logs = response.data.Response;
        this.lastLog = response.data.Response.slice(-1).pop();
      });
    },
    async update() {
      this.$vs.loading();
      return instance({
        method: "post",
        url: "/api/Parametros/AtualizarParametros",
        data: this.dataParams
      })
        .then(response => {
          console.log("ok");
          this.emailTemplate[0].EEmailTemplate = this.emailTemplate[0].EmailTemplate;
          return instance({
            method: "post",
            url: "/api/Parametros/AtualizarEmailTemplate",
            data: this.emailTemplate
          })
            .then(response => {
              this.$vs.loading.close();
              this.$notify({
                title: "Success",
                message: "Dados atualizados com sucesso!",
                type: "success"
              });
              this.getData();
              this.ObterLogAuditoria();
            })
            .catch(error => {
              this.$vs.loading.close();
              this.$onpoint.errorModal(error.response.data.Errors);
            });
        })
        .catch(error => {
          this.$vs.loading.close();
          this.$onpoint.errorModal(error.response.data.Errors);
        });
    },
    async getData() {
      this.$vs.loading();
      return instance({
        method: "get",
        url: "/api/ParametroRenovacaoPorModalidade/BuscarPerfisDeUsuario"
      })
        .then(response => {
          this.dataParams = response.data.Response;
          return instance({
            method: "get",
            url: "/api/Parametros/ObterEmailTemplate?emailTemplate=39"
          })
            .then(response => {
              this.emailTemplate[0] = response.data.Response;
              this.$vs.loading.close();
            })
            .catch(error => {
              this.$vs.loading.close();
            });
        })
        .catch(error => {
          this.$vs.loading.close();
        });
    }
  },
  created() {
    this.$appInsights.trackEvent({
      name: "param-modalidade"
    });
  }
};
</script>

<style lang="scss" scoped>
.branch-container {
  position: relative;
  background: #fff;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 25px;

  .title {
    padding: 15px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    /* grayscale/gray-4 */

    color: #8e8e8e;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .modality-group-content:not(:last-of-type) {
    border-bottom: 1px solid #e4e1e1;
  }

  .modality-group-content:not(:first-of-type) {
    padding-top: 16px;
  }

  .modality-group-content {
    margin: 0 16px 16px;
    padding-bottom: 16px;

    .modality-group-title {
      justify-content: space-between;
      padding-bottom: 28px;
      padding-top: 0px;
    }

    .data-content {
      color: #50555a;
      font-size: 14px;
      font-weight: 700;
    }

    .data-title {
      color: #8e8e8e;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.card-gray {
  background: #f7f7f7;
  color: #636363;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid #63636321;
  padding: 25px 15px;
  display: flex;

  .left,
  .right {
    width: 50%;
  }

  .left {
    text-align: left;
  }

  .right {
    display: flex;
    flex-direction: row-reverse;
  }
}

.main-card {
  padding: 15px 25px;
}

.history {
  position: absolute;
  top: -65px;
  right: 0;
}

.vs-popup {
  width: auto !important;
}
.addtag {
  float: right;
  border-radius: 0px !important;
}

.btn-view-changes {
  height: fit-content;
  padding: 10px 24px 10px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;

  &:hover {
    background: rgba(var(--vs-primary), 0.8) !important;
  }
}

.el-button.is-disabled {
  background: rgba(var(--vs-primary), 0.8) !important;
  pointer-events: none;
}

.pop-up-content {
  &-header {
    width: 100%;
    text-align: center;
    color: rgba(var(--vs-primary), 1);
    padding-bottom: 16px;
    border-bottom: 1px solid #747474;

    & i {
      width: 20px;
      height: 20px;

      &::before {
        font-size: 20px;
      }
    }

    & h3 {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 8px;
    }
  }

  &-comparation {
    padding: 36px 0 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-header {
      width: 100%;
      display: flex;
      padding: 0 16px 0 0;

      &-before,
      &-after,
      &-divider,
      &-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &-divider {
        margin: 0 18px;
      }

      &-before,
      &-after,
      &-data {
        width: 100%;
        height: 50px;
        border-radius: 8px 8px 0 0;
        font-size: 18px;
        font-weight: 700;
        color: rgba(13, 27, 51, 1);
      }

      &-data {
        background-color: rgba(236, 236, 236, 1);
      }

      &-before {
        background-color: rgba(236, 236, 236, 1);
      }

      &-after {
        background-color: rgba(174, 255, 192, 1);
      }
    }

    &-items {
      height: 450px;
      overflow: auto;
    }

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 36px 0;
      border-bottom: 1px solid rgba(116, 116, 116, 1);

      &-before,
      &-after,
      &-divider,
      &-divider-arrow,
      &-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-before,
      &-after,
      &-data {
        width: 100%;
      }

      &-divider {
        margin: 0 18px;
      }

      &-divider-arrow {
        & i {
          width: 36px;
          height: 36px;

          &::before {
            font-size: 36px;
            color: rgba(236, 236, 236, 1) !important;
          }
        }
      }

      &-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &-title {
        width: 50%;
        text-align: end;
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
        word-break: break-word;
      }

      &-info {
        width: 50%;
        margin-left: 8px;
        font-size: 12px;
        line-height: 20px;
        color: rgba(13, 27, 51, 1);
        word-break: break-word;
      }
    }

    &-item:last-child {
      border-bottom: none;
    }
  }
}

.pop-up-footer {
  padding: 36px 36px;
  border-top: 1px solid rgba(116, 116, 116, 1);
}
</style>
